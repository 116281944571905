import React from 'react';
import {TableProps} from '@backstage/core-components';
import {
  CatalogIndexPage,
  CatalogTable,
  CatalogTableColumnsFunc,
  CatalogTableRow,
} from '@backstage/plugin-catalog';
import {
  EntityKindPicker,
  EntityTypePicker,
  EntityOwnerPicker,
  EntityLifecyclePicker,
  EntityTagPicker,
  EntityProcessingStatusPicker,
  UserListPicker,
  useStarredEntities,
  EntityRefLinks,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import {Chip, IconButton, Tooltip, withStyles} from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import {Entity} from '@backstage/catalog-model';
import {IconLink, IntegrationIcon} from './IconLink';

const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);

export const CustomCatalogIndexPage = () => {
  const {toggleStarredEntity, isStarredEntity} = useStarredEntities();

  const actions: TableProps<CatalogTableRow>['actions'] = [
    {
      position: 'auto',
      action(rowData) {
        return {
          icon: () => {
            return (
              <IconButton aria-label="favorite" color="inherit">
                <Tooltip
                  title={
                    isStarredEntity(rowData.entity)
                      ? 'Remove from favorites'
                      : 'Add to favorites'
                  }
                >
                  {isStarredEntity(rowData.entity) ? (
                    <YellowStar />
                  ) : (
                    <StarBorder />
                  )}
                </Tooltip>
              </IconButton>
            );
          },
          onClick: (_event, data) => {
            if (!Array.isArray(data)) {
              toggleStarredEntity(data.entity);
            }
          },
        };
      },
    },
  ];

  return (
    <CatalogIndexPage
      actions={actions}
      columns={context => getColumnsFunction(context)}
      filters={
        <>
          <EntityKindPicker
            allowedKinds={['component', 'group', 'user', 'system', 'adr']}
          />
          <EntityTypePicker />
          <EntityOwnerPicker />
          <EntityLifecyclePicker />
          <EntityTagPicker />
          <EntityProcessingStatusPicker />
          <UserListPicker />
        </>
      }
    />
  );
};

function formatContent(entity: Entity, defaultKind?: string): string {
  return (
    entity.metadata?.title ||
    humanizeEntityRef(entity, {
      defaultKind: defaultKind,
    })
  );
}

const getColumnsFunction: CatalogTableColumnsFunc = ({filters}) => {
  if (filters.kind && filters.kind.value === 'user') {
    return [
      {
        title: 'Name',
        field: 'entity.spec.displayName',
        sorting: true,
        defaultSort: 'asc',
        customSort({entity: entity1}, {entity: entity2}) {
          return formatContent(entity1).localeCompare(formatContent(entity2));
        },
        highlight: true,
        render: row => (
          <EntityRefLinks entityRefs={[row.entity]} defaultKind="User" />
        ),
      },
      {
        title: 'Is active',
        field: "entity.metadata.annotations['deeplay.io/scim-is-active']",
        highlight: true,
        customSort: ({entity: entity1}, {entity: entity2}) => {
          const annotation1 =
            entity1.metadata.annotations &&
            entity1.metadata.annotations['deeplay.io/scim-is-active'] === 'true'
              ? true
              : false;
          const annotation2 =
            entity2.metadata.annotations &&
            entity2.metadata.annotations['deeplay.io/scim-is-active'] === 'true'
              ? true
              : false;
          return annotation1 === annotation2 ? 0 : annotation1 ? 1 : -1;
        },
        render: row => {
          if (
            !row.entity.metadata.annotations ||
            !row.entity.metadata.annotations['deeplay.io/scim-is-active']
          ) {
            return null;
          }
          const value =
            row.entity.metadata.annotations['deeplay.io/scim-is-active'];
          return value === 'true' ? (
            <Chip key={value} size="small" label="Active" color="primary" />
          ) : (
            <Chip key={value} size="small" label="Inactive" color="default" />
          );
        },
      },
    ];
  }

  if (filters.kind && filters.kind.value === 'adr') {
    return [
      {
        title: 'Name',
        field: 'entity.metadata.name',
        sorting: true,
        defaultSort: 'asc',
        customSort({entity: entity1}, {entity: entity2}) {
          return entity1.metadata.name.localeCompare(entity2.metadata.name);
        },
        highlight: true,
        width: '30%',
        minWidth: '300px',
        render: row => (
          <EntityRefLinks entityRefs={[row.entity]} defaultKind="ADR" />
        ),
      },
      {
        title: 'Title',
        field: 'entity.spec.title',
        width: '30%',
        minWidth: '250px',
      },
      {
        title: 'Path',
        field: 'entity.spec.path',
        width: '30%',
        minWidth: '300px',
      },
      {
        title: 'System',
        width: '10%',
        field: 'resolved.partOfSystemRelationTitle',
        render: ({resolved}) => (
          <EntityRefLinks
            entityRefs={resolved.partOfSystemRelations}
            defaultKind="system"
          />
        ),
      },
      {
        title: 'Owner',
        sorting: true,
        field: 'resolved.ownedByRelationsTitle',
        render: ({resolved}) => (
          <EntityRefLinks
            entityRefs={resolved.ownedByRelations}
            defaultKind="group"
          />
        ),
      },
      {
        title: 'Status',
        field: 'entity.spec.status',
      },
      {
        title: 'Date',
        field: 'entity.spec.recordDate',
      },
      {
        title: '',
        field: 'entity.metadata.links',
        render: ({entity}) => {
          if (!entity.metadata.links) {
            return null;
          }
          const link = entity.metadata.links[0];
          return (
            <IconLink
              href={link.url || ''}
              Icon={() => IntegrationIcon(link.url)}
              onlyIcon={true}
            />
          );
        },
      },
    ];
  }

  if (filters.kind && filters.kind.value === 'group') {
    return [
      CatalogTable.columns.createNameColumn({defaultKind: 'Group'}),
      CatalogTable.columns.createSpecTypeColumn(),
      CatalogTable.columns.createMetadataDescriptionColumn(),
      CatalogTable.columns.createTagsColumn(),
    ];
  }

  if (filters.kind && filters.kind.value === 'system') {
    return [
      CatalogTable.columns.createNameColumn({defaultKind: 'System'}),
      CatalogTable.columns.createOwnerColumn(),
      CatalogTable.columns.createMetadataDescriptionColumn(),
      CatalogTable.columns.createTagsColumn(),
    ];
  }

  return [
    CatalogTable.columns.createNameColumn(),
    CatalogTable.columns.createSystemColumn(),
    CatalogTable.columns.createOwnerColumn(),
    CatalogTable.columns.createSpecTypeColumn(),
    CatalogTable.columns.createSpecLifecycleColumn(),
    CatalogTable.columns.createMetadataDescriptionColumn(),
    CatalogTable.columns.createTagsColumn(),
  ];
};
