import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {
  ProviderSettingsItem,
  UserSettingsPage,
} from '@backstage/plugin-user-settings';
import {apis, bouncerOIDCAuthApiRef} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';
import {SearchContextProvider} from '@backstage/plugin-search-react';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import {useApi, configApiRef} from '@backstage/core-plugin-api';
import {CustomCatalogIndexPage} from './components/catalog/CatalogIndexPage';
import {CatalogUnprocessedEntitiesPage} from '@backstage/plugin-catalog-unprocessed-entities';
import CloudIcon from '@material-ui/icons/Cloud';
import IdentityIcon from '@material-ui/icons/PermIdentity';
import {MiroIcon} from './icons/Miro';
import {GrafanaIcon} from './icons/Grafana';
import {NotionIcon} from './icons/Notion';
import {AlertmanagerIcon} from './icons/Alertmanager';
import {SystemsList} from './components/catalog/system/SystemsPage';

const app = createApp({
  apis,
  icons: {
    alertManager: AlertmanagerIcon,
    notion: NotionIcon,
    cloud: CloudIcon,
    grafana: GrafanaIcon,
    miro: MiroIcon,
  },
  components: {
    SignInPage: props => {
      const configApi = useApi(configApiRef);
      const env = configApi.getOptionalString('auth.environment');

      if (env === 'development') {
        return (
          <SignInPage
            {...props}
            auto
            providers={[
              'guest',
              {
                id: 'bouncer',
                title: 'Bouncer',
                message: 'Sign in using Bouncer',
                apiRef: bouncerOIDCAuthApiRef,
              },
            ]}
          />
        );
      }
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'bouncer',
            title: 'Bouncer',
            message: 'Sign in using Bouncer',
            apiRef: bouncerOIDCAuthApiRef,
          }}
        />
      );
    },
  },
  bindRoutes({bind}) {
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="systems" />} />
    <Route path="/systems" element={<SystemsList />} />
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogIndexPage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route
      path="/settings"
      element={
        <UserSettingsPage
          providerSettings={
            <ProviderSettingsItem
              title="Bouncer"
              description="Provides sign-in via Bouncer"
              apiRef={bouncerOIDCAuthApiRef}
              icon={IdentityIcon}
            />
          }
        />
      }
    />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
  </FlatRoutes>
);

const App = app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>
        <SearchContextProvider>{routes}</SearchContextProvider>
      </Root>
    </AppRouter>
  </>,
);

export default App;
