import {RELATION_OWNED_BY, RELATION_PART_OF} from '@backstage/catalog-model';
import {
  HeaderIconLinkRow,
  IconLinkVerticalProps,
  InfoCard,
  InfoCardVariants,
} from '@backstage/core-components';
import {AboutField} from '@backstage/plugin-catalog';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {ADREntityV1alpha} from '@internal/backstage-plugin-adr-common';
import {
  CardContent,
  Chip,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import {IconLink, IntegrationIcon} from './IconLink';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
    padding: '0 !important',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
  details: {
    wordBreak: 'break-word',
  },
  detailsSubtitle: {
    fontWeight: 'bold',
    color: colors.grey[700],
    paddingRight: '8px',
  },
});

export const AdrCard = (props: {variant?: InfoCardVariants}) => {
  const {entity} = useEntity<ADREntityV1alpha>();
  const link = entity?.metadata?.links?.[0];

  const viewInSource: IconLinkVerticalProps = {
    label: 'View Source',
    icon: IntegrationIcon(link?.url || ''),
    href: link?.url || '',
  };

  const cardContentClass = useStyles();
  return (
    <InfoCard
      cardClassName={cardContentClass.gridItemCard}
      variant={props.variant}
      title={entity.spec.title || entity.metadata.name}
      subheader={<HeaderIconLinkRow links={[viewInSource]} />}
    >
      <CardContent className={cardContentClass.gridItemCardContent}>
        <AboutAdrContent entity={entity} />
      </CardContent>
    </InfoCard>
  );
};

type AboutAdrContentProps = {
  entity: ADREntityV1alpha;
};

function AboutAdrContent(props: AboutAdrContentProps) {
  const {entity} = props;
  const classes = useStyles();

  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system',
  });

  const partOfRelations = getEntityRelations(entity, RELATION_PART_OF);
  const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'domain',
  });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  return (
    <Grid container>
      <AboutField
        label={'Owner'}
        value={'No Owner'}
        gridSizes={{xs: 12, sm: 8, lg: 6}}
      >
        {ownedByRelations.length > 0 && (
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group" />
        )}
      </AboutField>
      {partOfDomainRelations.length > 0 && (
        <AboutField
          label={'Domain'}
          value={'No Domain'}
          gridSizes={{xs: 12, sm: 8, lg: 4}}
        >
          {partOfDomainRelations.length > 0 && (
            <EntityRefLinks
              entityRefs={partOfDomainRelations}
              defaultKind="domain"
            />
          )}
        </AboutField>
      )}

      {partOfSystemRelations.length > 0 && (
        <AboutField
          label={'System'}
          value={'No System'}
          gridSizes={{xs: 12, sm: 8, lg: 6}}
        >
          {partOfSystemRelations.length > 0 && (
            <EntityRefLinks
              entityRefs={partOfSystemRelations}
              defaultKind="system"
            />
          )}
        </AboutField>
      )}

      {partOfRelations.length > 0 && (
        <AboutField
          label={'Part of'}
          value={''}
          gridSizes={{xs: 12, sm: 8, lg: 6}}
        >
          <EntityRefLinks entityRefs={partOfRelations} />
        </AboutField>
      )}

      <AboutField
        label={'Tags'}
        value={'No Tags'}
        gridSizes={{xs: 12, sm: 8, lg: 6}}
      >
        {(entity?.metadata?.tags || []).map(tag => (
          <Chip key={tag} size="small" label={tag} />
        ))}
      </AboutField>
      <AboutField label={'Details'} gridSizes={{xs: 12}}>
        <AdrDetails className={classes.details} entity={entity} />
      </AboutField>

      {!!entity.spec.linksToPreviousVersions?.length && (
        <AboutField label={'Previous Versions'} gridSizes={{xs: 12}}>
          <ImageList rowHeight="auto" cols={1}>
            {entity.spec.linksToPreviousVersions.map((link, i) => (
              <ImageListItem key={i}>
                <IconLink href={link} text={link} Icon={undefined} />
              </ImageListItem>
            ))}
          </ImageList>
        </AboutField>
      )}
    </Grid>
  );
}

type AdrDetailsProps = {
  entity: ADREntityV1alpha;
  className?: string;
};

const AdrDetails = ({entity}: AdrDetailsProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography component="div">
        <Typography component="span" className={classes.detailsSubtitle}>
          Status:
        </Typography>{' '}
        <Typography component="span">{entity.spec.status || ''}</Typography>
      </Typography>
      <Typography component="div">
        <Typography component="span" className={classes.detailsSubtitle}>
          Decision date:
        </Typography>
        <Typography component="span">{entity.spec.recordDate}</Typography>
      </Typography>
      <Typography component="div">
        <Typography component="span" className={classes.detailsSubtitle}>
          Path:
        </Typography>
        <Typography component="span">{entity.spec.path}</Typography>
      </Typography>
    </>
  );
};
