import {Entity, EntityLink, stringifyEntityRef} from '@backstage/catalog-model';
import {
  OverflowTooltip,
  ResponseErrorPanel,
  Table,
  TableColumn,
} from '@backstage/core-components';
import {ScmIntegrationIcon} from '@backstage/integration-react';
import {
  EntityRefLinks,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import {ADREntityV1alpha} from '@internal/backstage-plugin-adr-common';
import {Chip} from '@material-ui/core';
import React, {useMemo} from 'react';
import {IconLink} from './IconLink';

const PAGE_SIZE = 10;

function getColumns(originalEntity: Entity): TableColumn<ADREntityV1alpha>[] {
  return [
    {
      title: 'Name',
      field: 'metadata.name',
      width: '30%',
      filtering: true,
      render: entity => (
        <EntityRefLinks entityRefs={[entity]} defaultKind="Component" />
      ),
    },
    {
      title: 'Title',
      field: 'spec.title',
      width: '30%',
      filtering: true,
      render: ({spec: {title}}) => <OverflowTooltip text={title} />,
    },
    {
      title: 'Status',
      field: 'spec.status',
      width: '10%',
      highlight: true,
    },
    {
      title: 'Record Date',
      field: 'spec.recordDate',
      width: '10%',
      filtering: false,
    },
    {
      title: 'Relation type',
      field: 'relations',
      width: '10%',
      render: ({relations}) => {
        const relevantRelations = relations?.filter(
          relation => relation.targetRef === stringifyEntityRef(originalEntity),
        );
        return (
          <>
            {relevantRelations?.map(relation => (
              <Chip
                key={relation.type}
                label={relation.type}
                color="primary"
                size="small"
              />
            ))}
          </>
        );
      },
    },
    {
      title: 'Link',
      field: 'metadata.links',
      width: '20%',
      render: ({metadata: {links}}) =>
        links?.length ? (
          <IconLink
            href={links[0].url}
            text="Source"
            Icon={() => getIcon(links[0])}
          />
        ) : null,
    },
  ];
}

const getLinkType = (link: {url: string} | undefined) => {
  if (!link) return undefined;
  if (link.url.includes('github')) return 'github';

  return undefined;
};

function getIcon(link?: EntityLink) {
  const type = getLinkType(link);
  if (type === 'github') {
    return <ScmIntegrationIcon type="github" />;
  }

  return <ScmIntegrationIcon />;
}

export const AdrListCard = () => {
  const {entity} = useEntity();

  const relatedAdrs = useRelatedEntities(entity, {kind: 'adr'});

  if (relatedAdrs.error) {
    return <ResponseErrorPanel error={relatedAdrs.error} />;
  }

  const columns = useMemo(() => getColumns(entity), [entity]);

  return (
    <Table<ADREntityV1alpha>
      title="Related Architecture Decision Records"
      options={{
        search: false,
        pageSize: PAGE_SIZE,
        padding: 'dense',
        sorting: true,
        draggable: false,
        paging: true,
        filtering: true,
        filterCellStyle: {padding: '0 16px 0 20px'},
      }}
      columns={columns}
      data={(relatedAdrs.entities as ADREntityV1alpha[]) || []}
    />
  );
};
